import { CommonMargin } from 'components/_layout/CommonMargin';
import React from 'react';
import { ContactForm } from 'sections/Contact/ContactForm/ContactForm';

export default () => {
    return (
        <CommonMargin>
            <ContactForm />
        </CommonMargin>
    );
};
